import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Post } from '../models/post';

@Injectable({
  providedIn: 'root'
})
export class HelloWorldService {

  readonly baseUrl: string = 'https://ancient-hamlet-79342.herokuapp.com/';
  readonly baseUrl2: string = 'https://jsonplaceholder.typicode.com/';

  constructor(private http: HttpClient) { }

  getMessage(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    return this.http.get(
      this.baseUrl + 'hello-world-pro',
      { headers, responseType: 'text'}
    );
  }


  getPosts(): Observable<Post[]> {
    return this.http.get<Post[]>(this.baseUrl2 + 'posts');
  }

}
