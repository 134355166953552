import { Component, OnInit } from '@angular/core';
import { Post } from '../models/post';
import { HelloWorldService } from '../services/hello-world.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
